import axios from "axios";
import { API_BASE_URL, MOCK_API_BASE_URL } from "utils/const";

axios.defaults.withCredentials = true;

export async function request(
  method,
  endpoint,
  query,
  payload,
  cancelToken,
  useMockApi = false,
  useFormData = false
) {
  let url = useMockApi ? MOCK_API_BASE_URL : API_BASE_URL;
  const options = {
    method,
    url: `${url}/${endpoint}${generateQuery(query)}`,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  };
  if (useFormData) {
    options.headers = {
      Accept: "application/vnd.api+json",
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Credentials": true,
    };
  }

  if (cancelToken) options.cancelToken = cancelToken;
  if (payload) options.data = payload;

  axios.interceptors.request.use(
    (config) => {
      config.withCredentials = true;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  let res;
  try {
    res = await axios(options);
    console.log("The response error: 1 ", res);
  } catch (error) {
    console.log("The response error: 2 ", error.response);
    if (axios.isCancel(error)) {
      return {
        success: false,
        data: error ? error.data : null,
        statusCode: error ? error.status : null,
        message: "Request Canceled",
      };
    }
    if (error.status == 401) {
      const event = new Event("not-authenticated");
      document.dispatchEvent(event);
    }
    return {
      success: false,
      data: error ? error.response.data : null,
      statusCode: error ? error.response.status : null,
      message: error.response.data.message,
    };
  }

  console.log(
    `${method} /${endpoint}${generateQuery(query)} `,
    "\nPayload: ",
    payload,
    "\nResponse: ",
    res.data
  );

  if (res.status == 401) {
    const event = new Event("not-authenticated");
    document.dispatchEvent(event);
  }

  return {
    success: res.status >= 200 && res.status < 300,
    data: res.data,
    statusCode: res.status,
    message: res.message,
  };
}

/* export function generateQuery(query) {
  return query
    ? Object.keys(query).reduce((acc, key) => {
      return (acc += `${acc ? "&" : "?"}${key}=${query[key]}`);
    }, "")
    : "";
} */

export function generateQuery(query) {
  return query
    ? Object.keys(query).reduce((acc, key) => {
      // Encode the key and value
      const encodedKey = encodeURIComponent(key);
      const encodedValue = encodeURIComponent(query[key]);

      return (acc += `${acc ? "&" : "?"}${encodedKey}=${encodedValue}`);
    }, "")
    : "";
}

export function get(endpoint, query, payload, cancelToken, useMockApi = false) {
  return request("get", endpoint, query, payload, cancelToken, useMockApi);
}

export function post(
  endpoint,
  query,
  payload,
  cancelToken,
  useMockApi = false,
  useFormData = false,
) {
  return request("post", endpoint, query, payload, cancelToken, useMockApi, useFormData);
}

export function put(endpoint, query, payload, cancelToken, useMockApi = false, useFormData = false) {
  return request("put", endpoint, query, payload, cancelToken, useMockApi, useFormData);
}

export function remove(endpoint, query, payload, cancelToken, useMockApi = false) {
  return request("delete", endpoint, query, payload, cancelToken, useMockApi);
}
