import axios from 'axios';

// Set session expiration time to 10 hours in milliseconds
const SESSION_EXPIRY_TIME = 10 * 60 * 60 * 1000; // 10 hours

// Utility function to get the current time in milliseconds
const getCurrentTime = () => new Date().getTime();

// Utility function to check if the session has expired
const isSessionExpired = (sessionStart) => {
    return getCurrentTime() - sessionStart > SESSION_EXPIRY_TIME;
};

// Utility function to extract the URL key (between 'api/v1/' and '?')
const extractApiKey = (url) => {
    const regex = /api\/v1\/([^?]*)/;
    const match = url?.match(regex); // Add optional chaining to handle undefined URLs
    return match ? match[1] : url;
};

// Initialize session storage if it doesn't exist or if expired
const initSessionStorage = () => {
    const sessionData = sessionStorage.getItem('apiLogs');
    const sessionStart = sessionStorage.getItem('sessionStart');

    if (!sessionData || !sessionStart || isSessionExpired(Number(sessionStart))) {
        sessionStorage.setItem('apiLogs', JSON.stringify([])); // Initialize as an array
        sessionStorage.setItem('sessionStart', getCurrentTime().toString());
    }
};

// Add request interceptor
axios.interceptors.request.use((config) => {
    initSessionStorage();

    // No changes here; just pass the request through
    return config;
}, (error) => {
    return Promise.reject(error);
});

// Add response interceptor
axios.interceptors.response.use((response) => {
    initSessionStorage();

    const apiLogs = JSON.parse(sessionStorage.getItem('apiLogs')) || [];
    const apiKey = extractApiKey(response.config.url); // Extract the URL part

    // Log the request and its result when the response is received
    apiLogs.push({
        endpoint: apiKey, // Include the endpoint in the object
        action: response.config.method.toUpperCase(), // GET, POST, PUT, DELETE, etc.
        success: response.status >= 200 && response.status < 300, // true for success
        timestamp: getCurrentTime(),
    });

    sessionStorage.setItem('apiLogs', JSON.stringify(apiLogs));

    return response;
}, (error) => {
    initSessionStorage();

    const apiLogs = JSON.parse(sessionStorage.getItem('apiLogs')) || [];
    const apiKey = error.config ? extractApiKey(error.config.url) : 'Unknown Endpoint'; // Fallback to 'Unknown Endpoint'

    // Log error details only if the config is available
    if (error.config) {
        apiLogs.push({
            endpoint: apiKey, // Include the endpoint in the object
            action: error.config.method.toUpperCase(), // GET, POST, PUT, DELETE, etc.
            success: false, // Failure case
            timestamp: getCurrentTime(),
        });
    } else {
        // Handle errors where config is not available (e.g., network errors)
        apiLogs.push({
            endpoint: 'Unknown Endpoint', // Could not retrieve the endpoint
            action: 'UNKNOWN', // Could not retrieve the action (method)
            success: false, // Failure case
            timestamp: getCurrentTime(),
        });
    }

    sessionStorage.setItem('apiLogs', JSON.stringify(apiLogs));

    return Promise.reject(error);
});
