import axios from "axios";

axios.interceptors.request.use(
  (config) => {
    console.log("authentication: in the request we have localstorage", localStorage.getItem("accessToken"));
    const accessToken = localStorage.getItem("accessToken");
    const sessionToken = localStorage.getItem("sessionToken");

    if (accessToken?.length > 95) config.headers["ACCESS-TOKEN"] = accessToken;
    if (sessionToken?.length > 95) config.headers["SESSION-TOKEN"] = sessionToken;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    console.log("Response:", response);
    if (response.headers["session-token"]) {
      localStorage.setItem("sessionToken", response.headers["session-token"]);
    }
    if (response.headers["access-token"]) {
      localStorage.setItem("accessToken", response.headers["access-token"]);
    }

    return response;
  },
  (error) => {
    console.error("Error response:", error.response);
    return Promise.reject(error);
  }
);
