import React, { lazy, StrictMode, Suspense } from "react";
import { createRoot } from "react-dom/client";
//import Loader from "react-promise-loader";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
//mui
import { StyledEngineProvider } from "@mui/material/styles";
//my layouts
const AuthLayout = lazy(() => import("layouts/Auth.js"));
const AdminLayout = lazy(() => import("layouts/Admin.js"));
import AdminHomeRoute from "layouts/AdminHomeRoute.js";
import AuthHomeRoute from "layouts/AuthHomeRoute.js";
import HomeRoute from "layouts/HomeRoute.js";
//views
const ChangePassword = lazy(
  () => import("views/Users/ChangePassword/ChangePassword")
);
//styles
import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";
import "react-toastify/dist/ReactToastify.css";
import "index.css";
//context
import { AuthenticationContextProvider } from "state/contexts/authenticationContext";
//my components
import SuspanseLoader from "components/common/Loader";
import PromiseLoader from "components/common/PromiseLoader.jsx";
//api global config
import "./services/common/axiosConfig.js";
import "./services/common/historyInterceptor.js";

const App = () => {
  return (
    <Suspense
      fallback={
        <div style={{ width: "100vw", height: "100vh" }}>
          <SuspanseLoader />
        </div>
      }
    >
      <BrowserRouter>
        <AuthenticationContextProvider>
          <Routes>
            <Route path="/changePassword" element={<ChangePassword />} />
            <Route
              path="/auth/*"
              element={
                <AuthHomeRoute>
                  <AuthLayout />
                </AuthHomeRoute>
              }
            />
            <Route
              path="/admin/*"
              element={
                <AdminHomeRoute>
                  <AdminLayout />
                </AdminHomeRoute>
              }
            />
            <Route path="/*" element={<HomeRoute />} />
          </Routes>
          <ToastContainer autoClose={500} />
          <PromiseLoader />
        </AuthenticationContextProvider>
      </BrowserRouter>
    </Suspense>
  );
};

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <StyledEngineProvider injectFirst>
    <App />
  </StyledEngineProvider>
);
