import React from "react";
import { Navigate, useLocation } from "react-router-dom";
//context
import { useAuthenticationContext } from "state/contexts/authenticationContext";
//my components
import SuspanseLoader from "components/common/Loader";

const AuthHomeRoute = ({ children }) => {
  //CUSTOM CLASSES
  const { authenticated } = useAuthenticationContext();
  const location = useLocation();
  const ADMIN_PAGE_REGEX = /^\/admin\/.*/;
  let fromLocation = undefined;
  if (ADMIN_PAGE_REGEX.test(location.pathname)) {
    fromLocation = location;
  }

  console.log("authentication from auth home: inside protected route?", authenticated);

  if (authenticated === "loading") {
    return (
      <div style={{ width: "100vw", height: "100vh" }}>
        <SuspanseLoader />
      </div>
    )
  }

  if (authenticated === true) {
    return <Navigate to="/admin/logs" replace />
  }

  return children;
}

export default AuthHomeRoute;
