import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  loaderContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  loader: {
    width: "64px",
    height: "12px",
    background: `
          radial-gradient(circle 6px, #FFF 100%, transparent 0),
          radial-gradient(circle 6px, #FFF 100%, transparent 0)
        `,
    backgroundSize: "12px 12px",
    backgroundPosition: "left center, right center",
    backgroundRepeat: "no-repeat",
    position: "relative",
    "&:before": {
      content: '""',
      position: "absolute",
      width: "12px",
      height: "12px",
      borderRadius: "50%",
      background: "#FF3D00",
      inset: 0,
      margin: "auto",
      animation: "$mvX 0.6s, $mvY 0.3s",
      animationTimingFunction: "cubic-bezier(.5,-300,.5, 300)",
      animationIterationCount: "infinite",
    },
  },
  "@keyframes mvX": {
    "100%": { left: "0.85px" },
  },
  "@keyframes mvY": {
    "100%": { top: "0.5px" },
  },
}));

const Loader = () => {
  const classes = useStyles();
  return (
    <div className="loading-page">
      <div className="loading-container">
        <img
          className="bimpos-logo"
          src="/bim-logo-colored.png"
          alt=".bimpos-logo"
        />
        <div className="loader-container">
          <span className="loader"></span>
        </div>
      </div>
    </div>
  );
};

export default Loader;
